import React, { useState } from 'react';
import './App.css';
import { letters } from './letters';

function App() {
  const [name, setName] = useState('');
  const [pattern, setPattern] = useState([]);

  const handleChange = (event) => {
    setName(event.target.value.toUpperCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const nameArray = name.split('');
    const newPattern = Array(6).fill('');

    nameArray.forEach(char => {
      if (letters[char]) {
        letters[char].forEach((line, index) => {
          newPattern[index] += line + '  ';
        });
      }
    });

    setPattern(newPattern);
  };

  return (
    <div className="App">
      <header className="App-header">
        <form onSubmit={handleSubmit}>
          <label>
            Nom:
            <input type="text" value={name} onChange={handleChange} />
          </label>
          <button type="submit">Masquer le Nom</button>
        </form>
        <pre style={{ fontSize: window.innerWidth <= 600 ? '6px' : '16px', marginTop: '20px' }}>
          {pattern.map((line, index) => (
            <div key={index} style={{ color: 'red' }}>{line}</div>
          ))}
        </pre>
      </header>
    </div>
  );
}

export default App;
